export default {
  selectTtarea (Vue) {
    const tables = Vue.$offline.db.tables
    const ttareaRes = Vue.$offline.ttarea
    return Vue.$offline.db
      .select()
      .from(tables.ttarea)
      .innerJoin(ttareaRes.dummyTable, ttareaRes.pk.eq(ttareaRes.dummyPk))
      .where(
        Vue.$offline.db.op.and(
          tables.ttarea.estado.gt(0)
        )
      )
      .exec()
  },
  selectTtareaDeTecnico (Vue, idtecnico) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.ttarea)
      .innerJoin(
        tables.tecnico_ttarea,
        tables.tecnico_ttarea.idttarea.eq(tables.ttarea.idttarea)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.ttarea.estado.gt(0),
          tables.tecnico_ttarea.idtecnico.eq(idtecnico),
          tables.tecnico_ttarea.estado.gt(0)
        )
      )
      .exec()
  },
  selectTecnicosAsignados (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_tecnico)
      .innerJoin(
        tables.tecnico,
        tables.parte_trabajo_tecnico.idtecnico.eq(tables.tecnico.idtecnico)
      )
      .innerJoin(
        tables.empleado,
        tables.tecnico.idempleado.eq(tables.empleado.idempleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_tecnico.idparte_trabajo.eq(idparteTrabajo)
        )
      )
      .exec()
  },
  selectSubsistemas (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo_subsis,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo_subsis.idorden_trabajo)
      )
      .innerJoin(
        tables.subsis,
        tables.orden_trabajo_subsis.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo),
          tables.orden_trabajo_subsis.estado.gt(0)
        )
      )
      .exec()
  },
  selectVehiculo (Vue) {
    const tables = Vue.$offline.db.tables
    const vehiculo = Vue.$offline.vehiculo
    return Vue.$offline.db
      .select()
      .from(tables.vehiculo)
      .innerJoin(vehiculo.dummyTable, vehiculo.pk.eq(vehiculo.dummyPk))
      .where(tables.vehiculo.estado.gt(0))
      .exec()
  },
}
